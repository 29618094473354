import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import "./assets/style/Pricing.css";

const CollapsedListElem = (props) => {
  const [isVisible, initHs] = useState(false);
  const invokeCollapse = () => initHs(!isVisible);

  const colSize = props.passes.length <= 4 ? Math.floor(12 / props.passes.length) : 3;
  const colClass = `col-lg-${colSize}`;

  return (
    <li>
      <a onClick={invokeCollapse}>
        {props.title}
        <i className="bx bx-chevron-down icon-show"></i>
        <i className="bx bx-chevron-up icon-close"></i>
      </a>
      <Collapse in={isVisible}>
        <div className="collapse" data-bs-parent=".accordion-list">
          <div className="row pricing-elem">
            {props.passes.map((pass, index) => (
              <div
                className={colClass}
                data-aos="fade-up"
                data-aos-delay={`${(index + 1) * 100}`}
                key={index}
              >
                <div className={`box ${pass.featured ? 'featured' : ''}`}>
                  <h3>{pass.title}</h3>
                  <h4>
                    {pass.price}
                    <sup>zł</sup>
                  </h4>
                </div>
              </div>
            ))}
            {props.additional && (
              <div className="col-lg-12">
                <p>{props.additional}</p>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </li>
  );
};

const Pricing = () => {
  return (
    <section id="pricing" className="pricing">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Cennik</h2>
        </div>
        <div className="row">
          <div
            className="col-lg-12 pt-4 pt-lg-0 content"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="accordion-list">
              <ul>
                <CollapsedListElem
                  title="Yoga i Pilates"
                  passes={[
                    {
                      title: "Wejście jednorazowe",
                      price: "40",
                    },
                    {
                      title: "4 zajęcia Yoga i Pilates",
                      price: "150",
                    },
                    {
                      title: "8 zajęć Yoga i Pilates",
                      price: "290",
                    },
                    {
                      title: "12 zajęć Yoga i Pilates",
                      price: "390",
                    },
                  ]}
                />
                <CollapsedListElem
                  title="Fitness"
                  passes={[
                    {
                      title: "Wejście jednorazowe",
                      price: "30",
                    },
                    {
                      title: "4 zajęcia Fitness",
                      price: "110",
                    },
                    {
                      title: "8 zajęć Fitness",
                      price: "210",
                    },
                    {
                      title: "12 zajęć Fitness",
                      price: "280",
                    },
                  ]}
                />
                <CollapsedListElem
                  title="Pakiet mieszany"
                  passes={[
                    {
                      title: "5 zajęć Fitness, 5 zajęć Yoga/Pilates",
                      price: "300",
                    },
                  ]}
                />
                <CollapsedListElem
                  title="Treningi personalne"
                  passes={[
                    {
                      title: "1 trening",
                      price: "150",
                    },
                    {
                      title: "Pakiet 10 treningów",
                      price: "1400",
                    },
                  ]}
                  additional="Zapisy przez telefon lub email"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
